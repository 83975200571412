import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';

import missionImage from '../images/missionheader.svg';
import preMobileMissionImage from '../images/missionHeaderSmall.svg';
import PartnersSection from '../page-components/mission/partnersSection';
import OurSponsorSection from '../page-components/mission/ourSponsorSection';
import OurCommunity from '../page-components/mission/ourCommunity';

function MissionPage({ data, location }) {
  const headerContent = {
    heading: 'Mission',
    subheading:
      'We are bringing together a diverse network of people who will support, challenge, and inspire one another by working on the country’s most pertinent technology and health system problems. We believe pooling our knowledge and working together will produce the best possible solutions.',
    image: missionImage,
    preMobileImage: preMobileMissionImage,
    alt: 'People with a mission',
  };

  return (
    <Layout title="Mission | HIVE" headerContent={headerContent} location={location}>
      <div className="mx-auto my-0 xl:w-[1246px] lg:w-[980px] mobile:w-[378px] mobile:px-[15px]">
        <div className="xl:w-[1246px] lg:pt-[60px] lg:w-[980px] md:w-[768px] mobile:w-full mobile:items-center flex sm:justify-start lg:justify-center my-0 sm:flex-wrap lg:flex-nowrap">
          <h2
            className="relative
                    font-bold
                    text-gray
                    font-roboto
                    text-2xl
                    sm:w-full
                    sm:pt-[100px]
                    sm:pb-[54px]
                    sm:justify-start
                    mobile:justify-center
                    lg:mb-[unset]
                    lg:h-[93px]
                    lg:w-[155px]
                    lg:pt-[55px]
                    flex
                    justify-center"
          >
            {data.contentfulMissionPage.partnerTitle}
          </h2>
        </div>
        <div className="xl:w-[1246px] lg:pt-[29px] lg:ml-0 lg:w-[980px] md:w-[768px] mobile:w-full flex mobile:flex-col mobile:items-center sm:justify-start lg:justify-center my-0 sm:flex-wrap lg:flex-nowrap">
          {data?.contentfulMissionPage?.missionPartner.map((partnerData) => {
            return (
              <PartnersSection partnerData={partnerData} key={partnerData.id} />
            );
          })}
        </div>
      </div>
      <OurSponsorSection data={data?.contentfulMissionPage} />
      <OurCommunity data={data?.contentfulMissionPage} />
    </Layout>
  );
}

export default MissionPage;

export const pageQuery = graphql`
  {
    contentfulMissionPage {
      id
      title
      partnerTitle
      sponsorVideoLink
      sponsorTitle
      sponsorText
      sponsorTextLink
      sponsorHeaderTitle
      missionPartner {
        id
        title
        linkUrl
        altText
        partnerImage {
          file {
            url
          }
        }
      }
      communityTitle
      ourCommunityRef {
        id
        title
        altText
        content
        image {
          file {
            url
          }
        }
      }
    }
  }
`;
