import React from 'react';

function PartnersSection({ partnerData }) {
  return (
    <a
      href={partnerData.linkUrl}
      className="flex sm:last:basis-full sm:basis-[33%] sm:pb-[88px] sm:last:pb-[100px] md:basis-[27%] md:even:mr-[unset] lg:even:mr-0 lg:even:pl-[unset] lg:justify-center lg:last:pb-[95px] lg:pb-[95px] xl:last:pb-[115px] xl:pb-[115px] lg:mr-0 lg:w-[116px] lg:max-w-[286px] xl:max-w-[306px] lg:even:ml-0"
    >
      <img src={partnerData.partnerImage.file.url} alt={partnerData.altText} />
    </a>
  );
}

export default PartnersSection;
