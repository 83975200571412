import React from 'react';

function OurCommunity({ data }) {
  return (
    <div className="flex sm:pb-[28px] lg:flex-row min-h-[445px] lg:pb-[67px] xl:min-h-[475px]">
      <div className="flex mx-auto my-0 sm:mt-[66px] sm:flex-col-reverse sm:mt-[60px] lg:flex-row lg:w-[980px] lg:mt-[75px] lg:ml-[auto] lg:pl-[44px] xl:w-[1246px]">
        <div className="flex font-display flex-col pt-0 sm:pl-[22px] sm:pr-[22px] lg:pl-0 lg:pr-0 lg:pt-[10px] lg:ml-[15px]">
          <div className="flex justify-center text-center text-[32px] text-blue font-bold sm:pb-[100px] lg:w-[inherit] lg:pb-[92px]">
            {data?.communityTitle}
          </div>
          <div className="flex sm:flex-col lg:flex-row flex-wrap lg:justify-evenly lg:gap-x-[58px] xl:gap-x-[189px]">
            {data?.ourCommunityRef.map((communityItem) => {
              return (
                <div
                  key={communityItem.id}
                  className="sm:leading-[30px] sm:pb-[72px] leading-[30px] lg:max-h-full text-[17px] lg:w-[259px] lg:pb-[111px] lg:last:ml-[-150px]"
                >
                  <div className="relative sm:mb-[22px] lg:mb-[15px] lg:ml-0 w-[75px] h-[75px] mobile:mx-auto">
                    <img
                      className="
                        absolute
                        bottom-0
                        mobile:left-[50%]
                        mobile:translate-x-[-50%]
                      "
                      src={communityItem.image.file.url}
                      alt={communityItem.altText}
                    />
                  </div>
                  <div className="font-bold text-blue flex sm:justify-center lg:justify-start">
                    {communityItem.title}
                  </div>
                  <div className="flex justify-center sm:max-w-full sm:text-center sm:mx-auto lg:text-left lg:mx-[unset] max-w-[259px] lg:text-[17px]">
                    {communityItem.content}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurCommunity;
