import * as React from 'react';

function OurSponsorSection({ data }) {
  return (
    <div
      className="flex lg:flex-row min-h-[445px] xl:min-h-[475px] pb-[67px] mobile:px-[15px]"
      style={{
        background: 'linear-gradient(113.22deg, #3034B0 51.37%, #854254 88.51%)',
      }}
    >
      <div className="flex justify-center mobile:items-center mx-auto my-0 sm:mt-[66px] sm:w-full sm:flex-col-reverse sm:mt-[60px] lg:flex-row lg:w-[980px] lg:mt-[55px] lg:ml-[auto] lg:pl-[44px] xl:w-[1246px]">
        <div className="flex justify-end sm:ml-[22px] sm:mt-[66px] sm:w-[320px] lg:mt-[42px] lg:w-[400px] lg:h-[230px] lg:ml-[inherit] lg:mr-[45px] xl:mt-0 xl:w-[608px] xl:h-[341px] xl:mr-[45px]">
          <iframe
            src={data.sponsorVideoLink}
            title="Sponsor video"
            className="flex lg:justify-end sm:h-[182px] sm:w-[322px] lg:h-full lg:w-full"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className="flex font-display text-white flex-col pt-0 lg:pl-0 lg:pr-0 lg:pt-[10px] lg:ml-[65px]">
          <div className="text-lg font-bold">{data.sponsorHeaderTitle}</div>
          <div className="text-white font-bold sm:leading-[30px] sm:text-[25px] sm:max-h-[60px] sm:pt-[26px] sm:pb-[55px] lg:leading-[50px] lg:max-h-full lg:text-[40px] lg:max-w-[473px] lg:pt-[29px] lg:pb-0">
            {data.sponsorTitle}
          </div>
          <div className="text-white text-[17px] sm:max-h-[202px] sm:pt-[30px] lg:text-lg lg:max-w-[473px] lg:pt-[30px] xl:pt-[26px]">
            {data.sponsorText}
            <a
              href={data.sponsorTextLink}
              className="font-bold hover:underline lg:block xl:inline-block"
            >
              {' '}
              Read More &gt;&gt;
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurSponsorSection;
